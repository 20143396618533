<template>
    <modal show="show" :modalLarge="true">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel">View Document</h6>
                <button type="button" class="btn btn-primary" v-on:click="printInvoice()">&nbsp; Print</button>
                <button type="button" class="btn-close" v-on:click="close()"></button>
            </div>
            <div class="modal-body text-center">
                <main>
                    <div class="box box-border" ref="printable" id="printable">


                        <table style="width: 100%">
                            <thead v-if="isHeaderFooter=='true'">
                                <tr>
                                    <th>
                                        <HeaderComponent></HeaderComponent>
                                        <!-- <div class="" style=" position:relative;  overflow: hidden !important;">
                                            <img src="header.png" style="width: 100%;height:90px">
                                        </div> -->


                                    </th>
                                </tr>
                            </thead>
                            <div v-else style="margin-top: 100px !important;width: 100% !important;">
                               
                            </div>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>



                                            <div style="padding-top: 16px;">
                                                <div class="row hide-on-print">
                                                    <div class="col-lg-4">
                                                        <div class="checkbox form-check-inline mx-2">
                                                            <input type="checkbox" id="inlineCheckbox1"
                                                                v-model="list.isHide">
                                                            <label for="inlineCheckbox1"> Hide the Fee</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                            <div class="checkbox form-check-inline mx-2">
                                                                <input type="checkbox" id="inlineCheckbox23"
                                                                    v-model="isBank">
                                                                <label for="inlineCheckbox23"> Hide the Bank</label>
                                                            </div>
                                                    </div>

                                                </div>
                                                <table style="width: 100%">
                                                    <tr>
                                                        <td width="50%" class="vertical-top">
                                                            <table width='100%' class="icon-info-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style="width:20%;text-align:right !important">
                                                                            Bill To:</td>
                                                                        <td style="width:80%;text-align:left !important">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="width:20%;text-align:right !important">
                                                                        </td>
                                                                        <td class="info"
                                                                            style="width:80%;text-align:left !important;font-weight: bold; font-size: 16px; ">

                                                                            {{ list.customerNameEn }}
                                                                        </td>
                                                                    </tr>
                                                                   
                                                                    <tr
                                                                        v-if="list.customerTelephone != '' && list.customerTelephone != null">
                                                                        <td style="width:20%;text-align:right !important">
                                                                        </td>
                                                                        <td class="info"
                                                                            style="width:80%;text-align:left !important;font-weight: bold; font-size: 16px; ">

                                                                            {{ list.customerTelephone }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        v-if="list.customerAddress != '' && list.customerAddress != null">
                                                                        <td style="width:20%;text-align:right !important">
                                                                        </td>
                                                                        <td class="info"
                                                                            style="width:80%;text-align:left !important;font-weight: bold; font-size: 16px; ">

                                                                            {{ list.customerAddress }}
                                                                        </td>
                                                                    </tr>






                                                                </tbody>
                                                            </table>
                                                        </td>

                                                        <td width="50%" class="vertical-top">
                                                            <table class="float-right icon-info-table">
                                                                <tbody>
                                                                    <tr>

                                                                        <td class="info"
                                                                            style="font-weight: bold; font-size: 16px; width: 200px; text-align: right">

                                                                            <span>Invoice No : </span>
                                                                        </td>
                                                                        <td class="info"> {{ list.registrationNo }}</td>
                                                                    </tr>


                                                                    <tr>
                                                                        <td class="info"
                                                                            style="font-weight: bold; font-size: 16px; width: 200px; text-align: right">

                                                                            <span>Issue Date : </span>
                                                                        </td>
                                                                        <td class="info"> {{ list.date }}</td>
                                                                    </tr>
                                                                    <!-- <tr v-if="list.documentType===3" >
                                                                        <td style="width:20%;text-align:right !important">
                                                                        Case Type:
                                                                        </td>
                                                                        <td class="info"
                                                                            style="width:80%;text-align:left !important;font-weight: bold; font-size: 16px; ">

                                                                            {{ list.doctorName }}
                                                                        </td>
                                                                    </tr> -->




                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>







                                            <div class="invoice-box" style="padding-top: 16px">
                                                <table cellpadding="0" cellspacing="0" clas="invoice-services">
                                                    <tr class="heading">
                                                        <td style="border-right:1px dashed  black !important;border-left:1px solid  black !important;width: 10px"
                                                            class="text-left">
                                                            SL </td>
                                                        <td v-if="list.documentType===3" style="border-right:1px dashed  black !important;border-left:1px dashed  black !important;width: 210px"
                                                            class="text-left">
                                                            Case Name
                                                        </td>
                                                        <td v-else style="border-right:1px dashed  black !important;border-left:1px dashed  black !important;width: 210px"
                                                            class="text-left">
                                                            Item Name & Description
                                                        </td>

                                                        <td style="border-right:1px dashed  black !important;border-left:1px dashed  black !important;width: 80px"
                                                            class="text-center">Fee</td>
                                                        <td v-if="list.documentType===3 && list.doctorName ==='Hour Base'" style="border-right:1px dashed  black !important;border-left:1px dashed  black !important;width: 80px"
                                                            class="text-center">Hour</td>
                                                        <td v-else-if="list.documentType!=3" style="border-right:1px dashed  black !important;border-left:1px dashed  black !important;width: 80px"
                                                            class="text-center">Quantity</td>
                                                        <td style="border-right:1px solid  black !important;border-left:1px dashed  black !important;width: 80px"
                                                            class="text-right">Total Fee</td>
                                                    </tr>




                                                    <tr class="item invoice-item first"
                                                        v-for="(item, index) in list.saleItems" v-bind:key="item.id">
                                                        <td
                                                            style="border-right:1px dashed  black !important;border-left:1px solid  black !important">
                                                            {{ index + 1 }}</td>

                                                        <td style="border-right:1px dashed  black !important;border-left:1px dashed  black !important"
                                                            class="text-left" v-if="!item.serviceItem">{{ item.productName }}</td>
                                                        <td style="border-right:1px dashed  black !important;border-left:1px dashed  black !important"
                                                            class="text-left" v-else>{{ item.description }}</td>


                                                        <td v-if="list.isHide"
                                                            style="border-right:1px dashed  black !important;border-left:1px dashed  black !important"
                                                            class="text-right"> --
                                                        </td>
                                                        <td v-else
                                                            style="border-right:1px dashed  black !important;border-left:1px dashed  black !important"
                                                            class="text-right"> {{ item.unitPrice.toFixed(3).slice(0,
                                                                -1) }}
                                                        </td>



                                                        <td v-if="list.documentType===3 && list.doctorName ==='Hour Base'"  style="border-right:1px dashed  black !important;border-left:1px dashed  black !important"
                                                            class="text-center">{{ item.quantity }}</td>

                                                        <td v-else-if="list.documentType!=3 "  style="border-right:1px dashed  black !important;border-left:1px dashed  black !important"
                                                            class="text-center">{{ item.quantity }}</td>
                                                        <td v-if="list.isHide"
                                                            style="border-right:1px solid  black !important;border-left:1px dashed  black !important"
                                                            class="text-right">
                                                             --
                                                        </td>
                                                        <td v-else
                                                            style="border-right:1px solid  black !important;border-left:1px dashed  black !important"
                                                            class="text-right">
                                                            {{currency}} {{ (item.total).toFixed(3).slice(0, -1) }}
                                                        </td>

                                                    </tr>




                                                </table>



                                            </div>
                                            <div style="padding-top: 20px !important;">
                                                <table width="100%">
                                                    <tr>
                                                        <td width="50%" style=" border-style: dashed;border: 1px dashed; text-align: left;"
                                                            v-if="list.note !== '' && list.note != null">
                                                            <span
                                                                style="color: black !important;text-align:left !important;">Remark:</span>
                                                            <br>

                                                            <span v-html="list.note"
                                                                style="color: black !important;text-align:left !important;  white-space: pre-line !important;"></span>


                                                        </td>
                                                        <td width="50%" v-else>


                                                        </td>
                                                        <td width="10%">

                                                        </td>
                                                        <td width="40%">
                                                            <table class="transaction-summary" width="100%">
                                                                <tr>
                                                                    <td class=" text-left">Net Payable:
                                                                    </td>
                                                                    <td class="text-right ">
                                                                        {{currency}} {{ Math.abs(list.grossAmount) }}
                                                                    </td>
                                                                </tr>
                                                                <tr v-if="list.discount != 0">
                                                                    <td class="text-left"
                                                                        style="border-top: 1px solid black">{{ list.refrenceNo }}:
                                                                    </td>
                                                                    <td class="text-right "
                                                                        style="border-top: 1px solid black;">
                                                                        {{currency}} {{ list.discount }}
                                                                    </td>
                                                                </tr>


                                                                <tr v-if="list.openingBalance > 0 && list.isCredit">
                                                                    <td class="text-left"
                                                                        style="border-top: 1px solid black;">Previous
                                                                        Due:
                                                                    </td>
                                                                    <td class="text-right "
                                                                        style="border-top: 1px solid black;">
                                                                        {{currency}} {{ Math.abs(list.openingBalance) }}
                                                                    </td>
                                                                </tr>


                                                                <tr v-if="list.isCredit">
                                                                    <td class="text-left"
                                                                        style="border-top: 1px solid black;">Grand
                                                                        Total:
                                                                    </td>
                                                                    <td class="text-right "
                                                                        style="border-top: 1px solid black;">
                                                                        {{currency}} {{ list.totalAmount }}
                                                                    </td>
                                                                </tr>

                                                                <tr v-if="isSaleOrder!=true">
                                                                    <td class="text-left"
                                                                        style="border-top: 1px solid black">Paid:
                                                                    </td>
                                                                    <td class="text-right "
                                                                        style="border-top: 1px solid black;">
                                                                        {{currency}} {{ Math.abs(list.paidAmount) }}
                                                                    </td>
                                                                </tr>
                                                                <tr v-if="list.totalAmount > 0 && list.isCredit">
                                                                    <td class="text-left"
                                                                        style="border-top: 1px solid black">Due:
                                                                    </td>
                                                                    <td class="text-right "
                                                                        style="border-top: 1px solid black;">
                                                                        {{currency}} {{ parseFloat(list.totalAmount) -
                                                                            parseFloat((Math.abs(list.paidAmount))) }}
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>




                                            <table style="width: 100%">
                                                <tr style=" height: auto">
                                                    <td style="width: 50%" v-if=" !isBank && list.bankDetails.length > 0" >
                                                        <div style="padding-top: 16px">
                                                            <h4
                                                                style="margin: 0; margin-bottom: 12px;color: black; text-align: left;">
                                                                Bank Details
                                                            </h4>
                                                            <table style="width: 100%; text-align:left" class="bank-table"  >
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Bank Name: </td>
                                                                        <td>{{ list.bankDetails[0].bankName }}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>Account No: </td>
                                                                        <td>{{ list.bankDetails[0].accountNumber }}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>Account Name:</td>
                                                                        <td> {{ list.bankDetails[0].accoutName }}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>Branch Name: </td>
                                                                        <td>{{ list.bankDetails[0].branchName }}</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                    <td style="width: 50%" v-else>
                                                        <div style="padding-top:130px !important">&nbsp;</div>
                                                    </td>
                                                    <td style="width: 20%">


                                                    </td>


                                                    <td style="width: 30%; vertical-align: bottom; text-align: right; ">
                                                        <hr style="border:1px dashed  black; border-width: 0.5px;">
                                                        Signature of Authorized Person


                                                    </td>

                                                </tr>
                                            </table>






                                        </div>


                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                            </tfoot>
                        </table>

                    </div>
                </main>
                <iframe ref="printFrame" style="display: none;"></iframe>

            </div>

        </div>

    </modal>
</template>
   


<script>
const options = {
    name: '',
    specs: [
        'fullscreen=no',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        '/assets/css/bootstrap.min.css',
    ],
    timeout: 700,
    autoClose: true,
    windowTitle: window.document.title,

}
import moment from "moment";

export default {
    props: ['printDetails', 'isTouchScreen', 'show','isSaleOrder','formName'],

    data: function () {
        return {
            isHeaderFooter: '',
            isPrint: false,
            currency: '',
            invoicePrint: '',
            IsDeliveryNote: '',
            arabic: '',
            english: '',
            userName: '',
            isMultiUnit: '',
            itemTotal: '',
            listItemP1: [],
            listItemP2: [],
            listItemP3: [],
            isBank: false,

            list: {
                number: 0,

                listItemTotal: [],
                registrationNo: '',
                date: '',
                dueDate: '',
                companyName: '',
                companyPhoneNo: '',
                companyAddress: '',
                discountAmount: '',
                cashCustomer: '',
                creditCustomer: '',
                customerPhoneNo: '',
                customerAddress: '',
                paymentMethod: '',
                paymentMethodNo: '',
                invocieType: '',
                saleItems:
                    [

                    ]
            },
            render: 0,
            company: {
                logoPath: '',
                companyNameEnglish: '',
                nameInEnglish: '',
                phoneNo: '',
                addressInEnglish: '',
            },

        }
    },


    methods: {
        printInvoice12: function () {

            var root = this;
            this.$htmlToPaper('purchaseInvoice', options, () => {

                root.$router.push({
                    path: 'SaleService'
                });




            });
        },
        printInvoice() {
            
            this.isPrint = true;

            var cssPath = window.location.origin + '/assets/css/A5Print.css';
            let printContents = this.$refs.printable.innerHTML;

            let printFrame = this.$refs.printFrame;

            if (printFrame) {
                let doc = printFrame.contentDocument || printFrame.contentWindow.document;
                doc.open();
                doc.write('<html><head><title>Invoice</title>');
                doc.write('<link rel="stylesheet" href="' + cssPath + '" type="text/css" />');
                doc.write('</head><body>');
                doc.write(printContents);
                doc.write('</body></html>');
                doc.close();

                printFrame.contentWindow.onload = function () {
                    printFrame.contentWindow.print();
                    this.isPrint = false;
                };
            } else {
                this.isPrint = false;
                // Handle if iframe reference is not available
            }
        },
       
        close: function () {
            
            this.$emit('close');
        },





    },
    mounted: function () {
        
        this.currency = localStorage.getItem('currency');
        this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
        

       
        this.isMultiUnit = localStorage.getItem('IsMultiUnit'); 
        if (this.printDetails.saleItems.length > 0) {
            this.list = this.printDetails;
            console.log( this.list)

            {




                this.list.date = moment(this.list.date).format('DD MMM YYYY');
                
            }

        }
        console.log('amb');
        console.log(this.list)
    },

}
</script>

<style scoped>
.logowrapper img {
    height: 100%;
}


* {
    line-height: unset
}

.box {
    margin: auto;
    padding: 12px;
    font-size: 16px;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    padding: 5px;
    vertical-align: top;
}


.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}


.invoice-box table tr.details td {
    padding-bottom: 20px;
}





td.all-remark p,
td.all-remark h2,
td.all-remark h3 {
    margin: 2px 0;
}

td.all-remark blockquote {
    border-left: 4px solid #d8dbe1;
    padding-left: 4px;
    margin: 4px 2px;
}

td.all-remark pre {
    background: white;
    padding: 8px 12px;
    border-radius: 10px;
}


table.transaction-summary {
    border-collapse: collapse
}

.invoice-box table tr.heading td {
    background: #eee;
    font-weight: bold;
}

.bank-table,
.invoice-box table {
    width: 100%;
    border-collapse: collapse;
}

.bank-table td,
.invoice-box table td {
    border: 1px solid;
    border-collapse: collapse;
}




.bank-table td {
    padding: 4px 8px;
}

table.transaction-summary tr td {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.float-right {
    float: right;
}

.vertical-top {
    vertical-align: top
}

.font-bold {
    font-weight: bold;
}


.icon-info-table tr td:first-child.info {
    padding-right: 4px;
}

.icon-info-table tr td:last-child {
    padding-left: 4px;
}

.icon-info-table tr td.icon {
    width: 20px;
    vertical-align: top
}

.icon-info-table tr td.info {
    font-size: 14px;
}

.page-break {
    page-break-after: always;
}

.button {
    gap: .5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    color: #306eb6;
    padding: 8px 0px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-bottom: 1px solid #306eb6;
}

.button svg {
    height: 20px;
}

thead th {
    height: 150px;
}

tfoot td {
    height: 30px;
}

.report-footer {
    position: fixed;
    bottom: 0px;
    height: 30px;
    display: block;
    width: 100%;
    text-align: center;
    /*border-top: solid 1px #ccc;*/
}

.box {
    width: 1000px;
}

.logo {
    max-height: 100px;
    object-fit: contain;
    object-position: top left;
}

.icon-info-table tr td.icon img {
    width: 15px;
    height: 15px;
    /* position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%) */
}
</style>